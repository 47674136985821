<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />

    <div class="col-md-12" style="margin-top:-15px;padding: 14px;" >
      <div class="row" >
        <div class="col-md-10">
          <h2 class="titulo-links-escolha">Livro Matrícula</h2>
        </div>
      </div>

      <div class="row" style="background-color: #f8f9fa;border: 1px solid #dee2e6;">
        <div class="field col-12 md:col-1 lg:col-2">
          <label>Selecione o modelo</label>
          <select class="p-inputtext p-component"
            style="appearance: revert !important;width: 100% !important;"
            v-model="selecao.modelo" @change="limparSegmento">
            <option value="modelo01">Modelo 01</option>
            <option value="modelo02"> Modelo 02</option>
          </select>
        </div>

        <div class="field col-12 md:col-6 lg:col-2" v-if="anoALL">
          <label>Ano:</label>
          <select class="p-inputtext p-component"
            disabled
            style="appearance: revert !important;width: 100% !important;"
            v-model="simples.ano" >
            <option v-for="a in anoALL" :key="a.ano" :value="a.ano" >{{a.ano}}</option>
          </select>
        </div>

        <!--<div class="field col-12 md:col-1 lg:col-1" v-if="selecao.modelo == 'modelo02'" >
          <label>Individual/Turma</label>
          <select class="p-inputtext p-component" v-model="simples.individual"
            style="appearance: revert !important;width: 100% !important;" >
              <option value="1"> Individual </option>
              <option value="0"> Turma </option>
          </select>
        </div>-->

        <div class="field col-12 md:col-2 lg:col-2" v-if="selecao.modelo == 'modelo01' ">
          <label>Selecione a Escola</label>
          <select class="p-inputtext p-component" v-model="simples.escola_id"
            style="appearance: revert !important;width: 100% !important;" >
            <option value="0" disabled selected> -- Escolha uma Escola -- </option>
            <option v-for="n in escolaALL" :key="n" :value="n.id" >{{n.nome}}</option>
          </select>
        </div>

        <div class="field col-12 md:col-2 lg:col-2" v-if="selecao.modelo === 'modelo02' && simples.individual ==='0' ">
          <label>Selecione a Escola</label>
          <select class="p-inputtext p-component" v-model="simples.escola_id"  @change="buscarSegmentoALLNovo()"
            style="appearance: revert !important;width: 100% !important;" >
            <option value="0" disabled selected> -- Escolha uma Escola -- </option>
            <option v-for="n in escolaALL" :key="n" :value="n.id" >{{n.nome}}</option>
          </select>
        </div>

        <div class="field col-12 md:col-2 lg:col-2" v-if="selecao.modelo === 'modelo02' && simples.individual ==='0' ">
          <label>Selecione a Segmento</label>
          <select class="p-inputtext p-component" v-model="simples.segmento_id2" @change="listaSerie(simples.segmento_id2)"
            style="appearance: revert !important;width: 100% !important;" >
            <option value="0" disabled selected> -- Escolha um Segmento -- </option>
            <option v-for="n in segmentoALL2" :key="n" :value="n.id" >{{n.nome}}</option>
          </select>
        </div>

        <div class="field col-12 md:col-1 lg:col-2" v-if="selecao.modelo === 'modelo02' && simples.individual ==='0' ">
          <label>Selecione a Serie</label>
          <select class="p-inputtext p-component" v-model="simples.serie_id2" @change="listaTurmas()"
            style="appearance: revert !important;width: 100% !important;" >
            <option value="0" disabled selected> -- Escolha uma serie -- </option>
            <option v-for="n in serieALL" :key="n" :value="n.id" >{{n.nome}}</option>
          </select>
        </div>

        <div class="field col-12 md:col-1 lg:col-2" v-if="selecao.modelo === 'modelo02' && simples.individual ==='0' ">
          <label>Selecione a Turma</label>
          <select class="p-inputtext p-component" v-model="simples.turma_id"
            style="appearance: revert !important;width: 100% !important;" >
            <option value="0" disabled selected> -- Escolha uma Turma-- </option>
            <option v-for="n in turmaALL" :key="n" :value="n.id" >{{n.nome}}</option>
          </select>
        </div>

        <div class="field col-12 md:col-3 lg:col-5" v-if="simples.escola_id != 0" style="margin-top: 39px;">
          <!--<button v-if="aguardeLoading == false" class="btn btn-primary" @click="createTable(); buscarRelatorioALL()" style="margin-top:10px;">Buscar Informações</button>-->
          <button v-if="aguardeLoading == false && selecao.modelo == 'modelo01'"
            class="btn btn-primary" @click="createTable();"  >Pesquisar</button>
          <button v-if="aguardeLoading == false && selecao.modelo == 'modelo02'"
            class="btn btn-primary" @click="createTable2();"  >Pesquisar</button>

          <button disabled v-if="aguardeLoading == true" class="btn btn-primary"  >Aguarde...</button>

          <button v-if="selecao.modelo == 'modelo01'" class="btn btn-waning" @click="visualizarorde = !visualizarorde" style="background-color: #e5e5e5;
            margin-left: 7px;"><b v-if="!visualizarorde">Visualizar </b> <b v-else>Ocultar </b> Ordem dos Segmentos</button>
        </div>
      </div>
    </div>

    <div class="pm-card">

      <div v-show="visualizarorde" style="padding-top: 21px;">
        <div class="p-fluid formgrid grid">

          <div class="field col-6 md:col-6 lg:col-6">
            <pm-OrderList v-model="segmento_item" listStyle="height:auto" dataKey="id" id="segmentos-relatorio">
              <template #header> Ordenação por segmentos </template>
              <template #item="slotProps">
                <div @click="selectOrderSegment(slotProps.item)">
                  <div >
                    <div>
                      <span class="" >{{slotProps.item.nome}}</span>
                    </div>
                  </div>
                </div>
              </template>
            </pm-OrderList>
          </div>

          <div class="field col-6 md:col-6 lg:col-6">
            <pm-OrderList v-model="serie_item" @update:modelValue="moveUp" listStyle="height:auto" dataKey="id" id="series-relatorio" >
              <template #header> Ordenação das series <span v-if="itemS.nome">{{ 'do '+ itemS.nome }}</span> </template>
              <template #item="slotProps">
                <div>
                  <div >
                    <div>
                      <span class="" >{{slotProps.item.nome}}</span>
                    </div>
                  </div>
                </div>
              </template>
            </pm-OrderList>
          </div>
        </div>
      </div>
      <div class="p-fluid formgrid grid" v-show="selecao.modelo ==='modelo02' && simples.turma_id >0 && pesquisar_modelo === true">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Aluno</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="r in this.relatorioALL2" :key="r">
              <td>{{ r.nome +' '+ r.sobrenome }}</td>
              <td>
                <button class="btn btn-primary" @click="visualizarIndividual(r)" style="margin-top:10px;margin-right:5px;"
                  title="Visualizar">
                  Visualizar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <pm-Dialog  header="Individual" v-model:visible="display_pdf" :style="{width: '100vw'}" :maximizable="true" :modal="true">
        <report02livro v-if="aluno" :aluno="aluno"> </report02livro>
        <template #footer>
        </template>
      </pm-Dialog>


      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12 lg:col-12" v-if="aguardeLoading == true" style="display: flex;">
          <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;" />
        </div>
        <div v-else>
          <report01table v-if="opentable" :thead="thead" :report="this.relatorioALL"></report01table>
        </div>
      </div>


    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from 'axios';
import { Escola } from "@/class/escolas";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { LoginInfo } from "@/class/login";

import report01table from "./components/report01TableComponent.vue"
import report02livro from "./components/tblLivroMatricula.vue"

import { Relatorio } from './relatorio.service'

export default defineComponent ({
  components:{
    report01table,report02livro
  },
  data(){
    return{
      base_url: axios.defaults.baseURL.replace('/api/v1',''),
      home: {icon: 'pi pi-home', to: '/relatorios/livro-matricula'},
      items_bread: [
        {label: 'Livro Matrícula'},
      ],

      selecao:{
        modelo:'modelo01'
      },
      pesquisar_modelo:false,
      display_pdf:false,
      visualizarorde: false,

      aluno:[],

      thead:[],
      segmento_item:[],
      serie_item:[],
      itemS: [],
      escolaALL:[],
      relatorioALL:[],
      relatorioALL2:[],
      segmentoALL: [],
      segmentoALL2: [],
      serieALL:[],
      turmaALL:[],
      anoAll:[],
      simples:{
        ano:2023,
        escola_id:0,
        segmento_id: 0,
        segmento_id2: 0,
        serie_id2: null,
        turma_id: null,
        individual: '0',
      },
      segmentos:[],
      serie:{},
      qdt_seg:0,
      todos_segmentos: 1,
      colunas:{
        numero: true
      },
      imprimir:0,
      visivel:false,
      aguardeLoading: false,
      opentable:false,
      prevRoute: null,
      who: [],
    }
  },
  async beforeMount() {
    this.simples.ano = parseInt(sessionStorage.getItem("anoSelecionado"));
    await this.logado();
    this.anosCalendario();
    this.buscarEscolaALL();
    this.buscarSegmentoALL();
    this.buscarSegmentoALL2();
  },
  methods: {
    async logado() {
      const token = sessionStorage.getItem("token");
      const whoiam = await LoginInfo.WhoIam(token);

      this.funcao = whoiam.data.funcao;
      this.who = whoiam.data;
    },
    limparSegmento(){
      if(this.selecao.modelo == "modelo02"){this.segmentoALL2 = [];}
    },
    async anosCalendario () {
      const dt = await Calendario.ano();
      this.anoALL = dt.data;
      this.anoALL.sort((a, b) => (a.ano > b.ano) ? 1 : ((b.ano > a.ano) ? -1 : 0));
    },

    async buscarEscolaALL() {
      this.escolaALL = [];
      //gestores
      if (this.funcao === 1 || this.funcao === 2 || this.funcao === 7) {
        const data = await Escola.obtemTodos();
        this.escolaALL = data.data;
      }
      //diretores

      if (this.funcao === 3 || this.who.diretor === 1) {

        await axios.get("/diretor/servidor/" + this.who.usuario.id + '/ano/'+ this.simples.ano)
          .then((resp) => {
            this.diretor = resp.data.id;
          })
          .catch((err) => {});
          console.log('al',this.diretor)
        const data = await Escola.obtemUm(this.diretor.escola);
        this.escolaALL.push(data.data);
      }
      //coordenadores
      if (this.funcao === 4) {
        const storageEscolas = sessionStorage.getItem("escolas");
        if (!storageEscolas) {
          await axios.get("/coordenador/servidor/" + this.who.usuario.id + '/ano/'+ this.simples.ano)
            .then((resp) => {
              this.coordenador = resp.data;
              sessionStorage.setItem(
                "coordenador_r",
                JSON.stringify(this.coordenador)
              );
            })
            .catch((err) => {});

          const escolas = this.coordenador.esc;
          for (let i = 0; i < escolas.length; i++) {
            const data = await Escola.obtemUm(escolas[i]);
            this.escolaALL.push(data.data);
          }
          sessionStorage.setItem("escolas", JSON.stringify(this.escolaALL));
        } else {
          this.escolaALL = JSON.parse(storageEscolas);

          const storageCoordenador = sessionStorage.getItem("coordenador_r");
          this.coordenador = JSON.parse(storageCoordenador);
        }
      }
    },

    async buscarSegmentoALL() {
      this.opentable = false;
      this.segmentoALL = [];
      this.serie = [];
      this.qdt_seg =0;

      let ok_ordem = false;

      if(sessionStorage.getItem("url") === null){
        sessionStorage.setItem("url", this.base_url);
      }

      if(sessionStorage.getItem("url") === this.base_url){
        if(sessionStorage.getItem("ordem_segmento") === null){
          ok_ordem = false;
        }else{
          ok_ordem = true;
        }

      }else{
        sessionStorage.removeItem("url");
        sessionStorage.setItem("url", this.base_url);
        sessionStorage.removeItem("ordem_segmento");
        ok_ordem = false;
      }


      if(ok_ordem === false){
        const data = await SegmentoEscolar.obtemTodos();
        this.segmento_item = data.data;

        for (const it of data.data) {
          const dt = await SegmentoEscolar.listarSegmentosPorSerie(it.id);
          const se = [];
          for (const ite of dt.data) {
            const n = {
              nome : ite.nome,
              id: ite.id,
              segmento_id: it.id
            };
            se.push(n);
          }

          it.series = se;
          this.segmentoALL.push(it)
        }

        if(this.segmento_item){
          for (let i = 0; i < this.segmento_item.length; i++) {
            const element = this.segmento_item[i]["series"];
            const s = [];
            for (let j = 0; j < element.length; j++) {
              s.push(element[j]["id"]);
            }
            this.segmento_item[i]["series_arr"] = s;
          }
        }

        sessionStorage.setItem("ordem_segmento", JSON.stringify(this.segmento_item));
      }else{
        const ordem_segmento = sessionStorage.getItem("ordem_segmento");
        this.segmento_item = JSON.parse(ordem_segmento);
      }
    },

    async buscarSegmentoALL2() {
      const data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL2 = data.data;
    },

    async createTable(){
      this.aguardeLoading = true;
      this.opentable = true;
      this.relatorioALL = [];
      this.relatorioALL2 = [];
      if(this.segmento_item){
        for (let i = 0; i < this.segmento_item.length; i++) {
          const element = this.segmento_item[i]["series"];
          const s = [];
          for (let j = 0; j < element.length; j++) {
            s.push(element[j]["id"]);
          }
          this.segmento_item[i]["series_arr"] = s;
        }
      }

      sessionStorage.removeItem("ordem_segmento");
      sessionStorage.setItem("ordem_segmento", JSON.stringify(this.segmento_item));


      this.thead = ["N°","MATRÍCULA",'DATA MATRÍCULA', 'PERÍODOS' ,"TURMA / TURNO","ALUNOS POR TURMA","ALUNO(A)","MÃE E PAI","NASCIMENTO","NATURALIDADE","SEXO","COR","CPF","ENDEREÇO","PCD", "RF"];

      this.relatorioALL = await Relatorio.modelo1(this.simples.ano,this.simples.escola_id,this.segmentos,this.serie,this.segmento_item)
      this.aguardeLoading = false;
    },

    async createTable2(){
      this.opentable = false;
      this.relatorioALL = [];
      this.aguardeLoading = true;
      this.pesquisar_modelo = true;
      const playload = {
        ano: this.simples.ano,
        turma_id: this.simples.turma_id,
        aluno_id: 0,
      };
      const res = await Relatorio.livroModelo2(playload,this.simples.individual);
      this.relatorioALL2 = res.data;
      this.aguardeLoading = false;
    },

    visualizarIndividual(n){
      this.aluno = [];
      this.aluno = n;
      this.display_pdf = true;
    },

    async gerarPdf(aluno){
      const res = await Relatorio.livroModelo2Table(aluno);
    },

    /*async buscarRelatorioALL() {
      this.aguardeLoading = true;
      this.imprimir = 0;
      this.relatorioALL = [];
      this.relatorioALL = await Relatorio.modelo1(this.simples.ano,this.simples.escola_id,this.segmentos,this.serie)
      await Relatorio.modelo1Table(this.relatorioALL);
      this.aguardeLoading = false;
    },*/

    async buscarSegmentoALLNovo() {
      this.segmentoALL2 = []
      const data = await SegmentoEscolar.obtemSegmentosEscola(this.simples.escola_id);
      this.segmentoALL2 = data.data;
    },

    async listaSerie (id) {
      this.turma_id = null;
      this.serie_id2 = null;
      //const data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      const data = await SegmentoEscolar.listarSegmentosPorSerieExistente(this.simples.escola_id,id);
      this.serieALL = data.data;
      return this.seriesALL;
    },
    async listaTurmas(){
      const play = {
        'escola':this.simples.escola_id,
        'serie': this.simples.serie_id2,
        'segmento': this.simples.segmento_id2,
      };

      const data = await Turma.pesqTurmaPorSerieSegmento(play);
      this.turmaALL = data.data;
    },

    selectOrderSegment(item){
      this.serie_item = item.series
      this.itemS = item;
    },

    moveUp(event){
      const found = this.segmento_item.find(element => element.id == event[0].segmento_id);
      found.series = [];
      found.series = event

      const index = this.segmento_item.indexOf(found);
      this.segmento_item.splice(index,found);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
      const divAta = document.getElementById("print");

      if ((vm.prevRoute.name === "atas" || vm.prevRoute.name === "ataAlunos") && divAta) {
        location.reload();
      }
    })
  },
});
</script>

<style>
  #segmentoOrdenados .p-picklist-source-controls{
    display:none;
  }

  #segmentoOrdenados .p-picklist-source-wrapper{
    display:none;
  }

  #segmentoOrdenados .p-picklist-transfer-buttons{
    display:none;
  }

  #segmentos-relatorio .p-orderlist-list-container .p-orderlist-header{
    font-size: 13px;
  }
  #segmentos-relatorio .p-orderlist-list-container .p-orderlist-list .p-orderlist-item {
    font-size:10px;
  }
  #series-relatorio .p-orderlist-list-container .p-orderlist-list .p-orderlist-item {
    font-size:10px;
  }
  #series-relatorio .p-orderlist-list-container .p-orderlist-header{
    font-size: 13px;
  }
</style>
