<template>
  <div class="p-fluid formgrid grid">
    <div class=" field col-12 md:col-12">
      <button class="btn btn-danger" @click="gerarPdf()" style="margin-top:10px;margin-right:5px;"
        title="Gerar Impressão">
        <i class="fa fa-file-pdf-o"></i> Pdf
      </button>

      <button class="btn btn-success" @click="gerarExcel()" style="margin-top:10px;"
        title="Gerar Excel">
        <i class="fa fa-file-excel-o"></i> Excel
      </button>
      <button class="btn btn-secondary ml-1" @click="gerarCsv('report02-livro')" style="margin-top:10px;"
        title="Gerar csv">
        <i class="fa fa-file-o"></i> CSV
      </button>
    </div>
    <div class=" field col-12 md:col-12" id="report02-livro">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
        <meta charset="utf-8">
      </head>

      <h4 style="float:right">Ano Letivo: {{ aluno.ano }} </h4>
      <table class="table table-bordered" border=1 cellpadding=5  cellspacing=0  >
        <thead>
          <tr>
            <th>Ano/Período</th>
            <th>Turma</th>
            <th>Cor/Raça</th>
            <th>N° Matricula</th>
            <th>Data Matricula</th>
            <th>Aluno</th>
            <th>Data de Nascimento</th>
            <th>Sexo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ aluno.turma.nome_serie }}</td>
            <td>{{ aluno.turma.nome }}</td>
            <td>{{ aluno.cor_raca }}</td>
            <td>{{ aluno.matricula }}</td>
            <td>{{ aluno.data_matricula }}</td>
            <td>{{ aluno.nome +' '+ aluno.sobrenome}}</td>
            <td>{{ aluno.nascimento }}</td>
            <td>{{ aluno.sexo }}</td>
          </tr>
        </tbody>
      </table>

      <tr>
        <td>
        </td>
      </tr>

      <!--<div style="display: flex;flex-direction: row;">-->
      <table style="width: 100%;">
        <tbody>
          <tr>
            <td>
              <table class="table table-bordered" border=1 cellpadding=5  cellspacing=0>
                <tbody>
                  <tr>
                    <td><b>Naturalidade:</b></td>
                    <td>{{ aluno.naturalidade }}</td>
                  </tr>

                  <tr>
                    <td><b>Endereço:</b></td>
                    <td>{{ aluno.endereco.logradouro }} {{ aluno.endereco.numero ? ' N° ' + aluno.endereco.numero : ' ' }}</td>
                  </tr>

                  <tr>
                    <td><b>Bairro:</b></td>
                    <td>{{ aluno.endereco.bairro }}</td>
                  </tr>

                  <tr>
                    <td><b>Cidade:</b></td>
                    <td>{{ aluno.endereco.cidade }}</td>
                  </tr>
                </tbody>
              </table>
            </td><td>
              <table class="table table-bordered" border=1 cellpadding=5  cellspacing=0>
                <tbody>
                  <tr>
                    <td><b>Pai:</b></td>
                    <td>{{ aluno.nome_pai }}</td>
                  </tr>

                  <tr>
                    <td><b>Mãe:</b></td>
                    <td>{{ aluno.nome_mae }}</td>
                  </tr>

                  <tr>
                    <td><b>Responsavel:</b></td>
                    <td>{{ aluno.nome_responsavel }}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <!--</div>-->
    </div>
  </div>
</template>

<script>

import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  props: {
    aluno:{},
  },
  data() {
    return {};
  },
  async beforeMount() {},
  methods: {
    gerarPdf(){
      const mywindow = window.open('', 'PRINT', 'height=auto,width=800');

      mywindow.document.write('<html><head><title>relatorio</title>');
      mywindow.document.write(`
        <style>
          body {
              margin: 0;
              color: #212529;
              text-align: center;
              background-color: #fff;
          }
          table {
              border-collapse: collapse;
          }
          table tr td, table tr th{
            font-size: 11px !important;
          }
          thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
          }
          tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
          }
          .table-bordered {
              border: 1px solid #dee2e6;
          }
          .table {
              width: 100%;
              margin-bottom: 1rem;
              color: #212529;
          }
          .table-bordered thead td, .table-bordered thead th {
              border-bottom-width: 2px;
          }
          .table thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
          }
          .table-bordered td, .table-bordered th {
              border: 1px solid #dee2e6;
          }
          .table td, .table th {
            padding: 0.75rem;
            font-size: 11px !important;
          }
        </style>
        `);
      mywindow.document.write('</head><body>');
      mywindow.document.write(`${document.getElementById('report02-livro').innerHTML}`);
      mywindow.document.write('</body></html>');


      mywindow.print();
      mywindow.close();

      return true;
    },
    gerarCsv(id) {
      // Obtém a div pelo id
      const div = document.getElementById(id);
      if (!div) {
        console.error('Div não encontrada: ' + id);
        return;
      }

      // Obtém todas as linhas da div
      const linhas = div.querySelectorAll('tr');

      // Cria o conteúdo do CSV
      let csv = '';
      linhas.forEach((linha) => {
        const celulas = linha.querySelectorAll('td');
        const valores = Array.from(celulas).map((celula) => celula.textContent);
        csv += valores.join(',');
        csv += '\n';
      });

      // Cria um Blob com o conteúdo do CSV
      const csvString = "\uFEFF" + csv;
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      // Cria uma URL para o Blob
      const url = URL.createObjectURL(blob);

      // Cria um link para download do arquivo CSV
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'livroMatricula.csv');

      // Adiciona o link ao corpo do documento e inicia o download
      document.body.appendChild(link);
      link.click();

      // Remove o link do corpo do documento
      document.body.removeChild(link);
    },
    gerarExcel(e){
      const a = document.createElement('a');
      const data_type = 'data:application/vnd.ms-excel';
      const table_div = document.getElementById('report02-livro');
      const table_html = table_div.outerHTML.replace(/ /g, '%20');
      a.href = data_type + ', ' + table_html;
      a.download = 'livromatricula.xls';
      a.click();
      e.preventDefault();
    }
  },
});
</script>

<style scoped>
body {
  margin: 0;
  color: #212529;
  text-align: center;
  background-color: #fff;
}
table {
    border-collapse: collapse;
}
table tr td, table tr th{
  font-size: 12px !important;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table-bordered {
    border: 1px solid #dee2e6;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}
.table td, .table th {
  padding: 0.75rem;
}

.report-enderenco{
  text-align:center; text-transform: uppercase;
}
.report-enderenco b{ font-weight: 400 !important; padding-right: 5px;}
</style>
