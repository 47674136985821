<template>
  <div>
    <div class="field col-12 md:col-12 lg:col-12" >
      <button class="btn btn-danger" @click="PrintElem('report01')" style="margin-top:10px;margin-right:5px;"
        title="Gerar Impressão">
        <i class="fa fa-file-pdf-o"></i> Pdf
      </button>
      <button class="btn btn-success" @click="PrintElem2('report01')" style="margin-top:10px;"
        title="Gerar Excel">
        <i class="fa fa-file-excel-o"></i> Excel
      </button>
      <button class="btn btn-secondary ml-1" @click="gerarCsv('report01')" style="margin-top:10px;"
        title="Gerar csv">
        <i class="fa fa-file-o"></i> CSV
      </button>

    </div>
    <div  id="report01">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
        <meta charset="utf-8">
      </head>
      <table class="table table-bordered" border=1 cellpadding=5  cellspacing=0>
        <thead>
          <tr v-if="thead" style="text-align: center;">
            <th v-for="th in thead" :key="th"> {{ th }} </th>
          </tr>
        </thead>
        <tbody v-if="report">
          <tr v-for="(student,index) in report" :key="student">
            <td>{{ index+1 }}</td>
            <td>{{student['aluno']['matricula']}}</td>
            <td>{{student['aluno']['data_matricula']}}</td>
            <td>{{student['serie_nome']}}</td>
            <td v-if="student['turma_nome']">{{ student['turma_nome']  +' '+ student['turno'] }}</td> <td v-else></td>
            <td v-if="student['quantidade_alunos']">{{ student['quantidade_alunos'] }}</td> <td v-else></td>
            <td v-if="student['aluno']['nome']">{{ student.aluno.nome  +' '+  student.aluno.sobrenome }}</td> <td v-else></td>
            <td v-if="student['aluno']['nome_mae']">{{ student['aluno']['nome_mae']  +' '+  student['aluno']['nome_pai'] }}</td> <td v-else></td>
            <td style="text-align:center;" v-if="student['aluno']['nascimento_formatado']">{{ student['aluno']['nascimento_formatado'] }}</td> <td v-else></td>
            <td style="text-align:center;" v-if="student['aluno']['naturalidade']">{{ student['aluno']['naturalidade'] }}</td> <td v-else></td>
            <td style="text-align:center;" v-if="student['aluno']['sexo']">{{ student['aluno']['sexo'] }}</td> <td v-else></td>
            <td style="text-align:center;" v-if="student['aluno']['cor_raca']">{{ student['aluno']['cor_raca'] }}</td> <td v-else></td>
            <td style="text-align:center;" v-if="student['aluno']['cpf']">{{ student['aluno']['cpf'] }}</td> <td v-else></td>

            <td class="report-enderenco" v-if="student['aluno']['enderecos']">
              {{ student['aluno']['enderecos']['logradouro'] ? ' '+student['aluno']['enderecos']['logradouro'] : '' }}
              {{ student['aluno']['enderecos']['numero'] ? ' '+student['aluno']['enderecos']['numero'] : ' ' }}
              {{ student['aluno']['enderecos']['bairro'] ? ' '+student['aluno']['enderecos']['bairro'] : ' ' }}
              {{ student['aluno']['enderecos']['cidade'] ? ' '+student['aluno']['enderecos']['cidade'] : ' '}}
              {{ student['aluno']['enderecos']['estado'] ? ' '+student['aluno']['enderecos']['estado'] : ' '}}
            </td>
            <td v-else></td>

            <td style="text-align:center;" v-if="student['aluno']['nee']">sim</td> <td v-else>não</td>
            <td style="text-align:center; background-color: transparent;"><p style="color: #fff;"></p></td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  props: {
    thead:{},
    report:{}
  },
  data() {
    return {

    };
  },
  async beforeMount() {

  },
  methods: {
     PrintElem(elem){
      const mywindow = window.open('', 'PRINT', 'height=auto,width=800');

      mywindow.document.write(`<html xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">

        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
          <meta charset="utf-8">
        <title>relatorio</title>`);
      mywindow.document.write(`
        <style>
          @page {
            size: A4 landscape;
          }
          body {
              margin: 0;
              color: #212529;
              text-align: center;
              background-color: #fff;
          }
          table {
              border-collapse: collapse;
          }
          table tr td, table tr th{
            font-size: 12px !important;
          }
          thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
          }
          tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
          }
          .table-bordered {
              border: 1px solid #dee2e6;
          }
          .table {
              width: 100%;
              margin-bottom: 1rem;
              color: #212529;
          }
          .table-bordered thead td, .table-bordered thead th {
              border-bottom-width: 2px;
          }
          .table thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
          }
          .table-bordered td, .table-bordered th {
              border: 1px solid #dee2e6;
          }
          .table td, .table th {
            padding: 0.75rem;
          }
        </style>
        `);
      mywindow.document.write('</head><body>');
      mywindow.document.write(`${document.getElementById(elem).innerHTML}`);
      mywindow.document.write('</body></html>');


      mywindow.print();
      mywindow.close();

      return true;
    },
    gerarCsv(id) {
      // Obtém a div pelo id
      const div = document.getElementById(id);
      if (!div) {
        console.error('Div não encontrada: ' + id);
        return;
      }

      // Obtém todas as linhas da div
      const linhas = div.querySelectorAll('tr');

      // Cria o conteúdo do CSV
      let csv = '';
      linhas.forEach((linha) => {
        const celulas = linha.querySelectorAll('td');
        const valores = Array.from(celulas).map((celula) => celula.textContent);
        csv += valores.join(',');
        csv += '\n';
      });

      // Cria um Blob com o conteúdo do CSV
      const csvString = "\uFEFF" + csv;
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      // Cria uma URL para o Blob
      const url = URL.createObjectURL(blob);

      // Cria um link para download do arquivo CSV
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'livroMatricula.csv');

      // Adiciona o link ao corpo do documento e inicia o download
      document.body.appendChild(link);
      link.click();

      // Remove o link do corpo do documento
      document.body.removeChild(link);
    },
    PrintElem2(e){
      const a = document.createElement('a');
      const data_type = 'data:application/vnd.ms-excel';
      const table_div = document.getElementById('report01');
      const table_html = table_div.outerHTML.replace(/ /g, '%20');
      //table_html = decodeURI(table_html);
      a.href = data_type + ', ' + table_html;
      a.download = 'livromatricula.xls';
      a.click();
      e.preventDefault();
    },
  },
});
</script>

<style scoped>
body {
  margin: 0;
  color: #212529;
  text-align: center;
  background-color: #fff;
}
table {
    border-collapse: collapse;
}
table tr td, table tr th{
  font-size: 12px !important;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table-bordered {
    border: 1px solid #dee2e6;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}
.table td, .table th {
  padding: 0.75rem;
}

.report-enderenco{
  text-align:center; text-transform: uppercase;
}
.report-enderenco b{ font-weight: 400 !important; padding-right: 5px;}
</style>

<style>
  /* Select the last th element in the thead */
  thead tr th:last-child {
    width: 150px; /* Set your desired width here */
  }
</style>

